<template>
  <div>
    <div>
      <div class="vx-row mb-3">
        <div class="vx-col lg:w-4/12 w-full">
          <!--header-->
          <vx-card class="mb-2" :is-refresh="isLoading">
            <div class="flex items-center">
              <feather-icon icon="PackageIcon" svgClasses="h-5 w-5" class="mr-2"/>
              <div class="flex justify-between items-center w-full">
                <h5 class="font-medium">PR Header</h5>
                <feather-icon @click="getInitData" icon="RefreshCwIcon" svgClasses="h-4 w-4" class="cursor-pointer"/>
              </div>
            </div>
          </vx-card>
          <vx-card class="mb-2" v-if="!isLoading">
            <div class="flex items-center mb-1">
              <p class="text-primary font-bold text-xl mr-2">No:</p>
              <span class="font-bold text-primary text-xl">{{ data.pengadaan.no_pengadaan || '' }}</span>
            </div>
            <div class="flex items-center mb-2">
              <div class="text-white rounded-sm text-center items-center px-1" :class="'bg-' + data.pengadaan.status_approval_color">
                <span class="text-xs">{{ data.pengadaan.status_approval }}</span>
              </div>
            </div>
            <p class="font-medium">{{ data.pengadaan.judul_pengadaan || '' }}</p>
            <p v-if="data.pengadaan.keterangan" class="opacity-75 text-sm mt-1">{{ data.pengadaan.keterangan || '' }}</p>
            <vs-divider/>
            <p class="font-semibold mb-3">Details</p>
            <div class="flex justify-between mb-2">
              <span class="text-left opacity-75">Proyek</span>
              <span class="text-right">{{ data.pengadaan.nama_proyek || '' }}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-left opacity-75">Tgl</span>
              <span class="text-right">{{ data.pengadaan.tgl_pengajuan || '' }}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-left opacity-75">Created At</span>
              <span class="text-right">{{ data.pengadaan.created_at || '' }}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-left opacity-75">Created By</span>
              <span class="text-right">{{ data.pengadaan.username || '' }}</span>
            </div>
            <vs-divider/>
            <div class="flex justify-between font-semibold mb-3">
              <span>Total Nilai PR</span>
              <span>{{ data.pengadaan.total_pengadaan | idr }}</span>
            </div>
            <div class="flex flex-row space-x-2 mt-3">
              <vs-button type="filled" color="primary" size="small" class="px-3 mr-3" icon-pack="feather" icon="icon-printer" v-if="data.pengadaan.status_approval === 'DITERIMA'" @click="print">Print</vs-button>
            </div>
          </vx-card>

          <!--approval progress-->
          <vx-card class="mb-2" v-if="!isLoading">
            <div class="flex items-center mb-base">
              <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
              <h5 class="font-medium">Approval Progress</h5>
            </div>
            <ApprovalProgress ref="approvalProgress"
                              type="PURCHASE-REQUEST"
                              :id-ref="$route.params.idPengadaan"
                              :isShowSigningAction="true"
                              @signingClicked="onClickSigningAction"/>
          </vx-card>
        </div>

        <!--list items-->
        <div class="vx-col lg:w-8/12 w-full relative">
          <vx-card class="mb-2" :is-refresh="isLoading">
            <div class="flex items-center">
              <feather-icon icon="ListIcon" svgClasses="h-5 w-5" class="mr-2"/>
              <h5 class="font-medium">List Items</h5>
            </div>
          </vx-card>
          <p v-if="data.pengadaanD.length < 1 && !isLoading" class="w-full p-3 text-center">Tidak ada item.</p>
          <div v-if="!isLoading">
            <vx-card v-for="item in data.pengadaanD" :key="item.id" class="mb-2">
              <div>
                <div class="flex items-center justify-between mb-2">
                  <h6 class="item-name font-semibold">{{ item.nama_item_pengadaan }} <span class="ml-1 opacity-50 text-sm">({{ item.kode_item_pengadaan }})</span></h6>
                  <p class="text-sm font-semibold text-primary">{{ (parseFloat(item.harga) * item.qty) | idr }}</p>
                </div>
                <div class="flex items-center mb-1">
                  <p class="text-sm font-medium">Quantity: {{ item.qty }}</p>
                  <p class="text-sm mx-2">|</p>
                  <p class="text-sm font-medium">Harga: {{ parseFloat(item.harga) | idr }}</p>
                </div>
                <div class="flex items-center cursor-pointer select-none" @click="item.showVendors = !item.showVendors">
                  <p class="w-auto text-sm">{{ item.vendors.length }} Pilihan Rekanan</p>
                  <feather-icon :icon="item.showVendors ? 'ChevronUpIcon' : 'ChevronDownIcon'" svgClasses="h-4 w-4" class="ml-1"/>
                </div>
                <div v-if="item.showVendors">
                  <div class="py-2 flex items-center justify-between border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-l-0 border-b"></div>
                  <div v-for="vendor in item.vendors" :key="vendor.id" class="py-3 flex items-center justify-between border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-l-0 border-b">
                    <div class="flex items-center">
                      <div class="mr-3">
                        <feather-icon v-if="vendor.is_final" icon="CheckIcon" svgClasses="h-6 w-6 mr-3 text-success"/>
                        <vs-icon v-else size="small" icon-pack="material-icons" icon="business"/>
                      </div>
                      <div>
                        <p class="text-sm font-semibold">{{ vendor.nama_rekanan || '-' }}</p>
                        <p class="text-sm font-medium text-success" v-if="vendor.is_final">Quantity: {{ vendor.qty_actual | idr }}</p>
                        <p class="text-sm font-medium text-primary">Harga: {{ vendor.harga | idr }}</p>
                        <p class="text-sm opacity-75" v-if="vendor.keterangan">{{ vendor.keterangan }}</p>
                      </div>
                    </div>
                    <div class="flex items-center">
                      <a :href="vendor.file_penawaran_url" target="_blank" v-if="vendor.file_penawaran_url">
                        <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
        </div>

      </div>
    </div>

    <!--modals-->
    <PurchaseRequestSigning :isActive.sync="modalSigning.active"
                       :idPengadaan="modalSigning.idPengadaan"
                       :status="modalSigning.status"
                       @success="onSigned"/>

    <PurchaseRequestFinalApprove :isActive.sync="modalFinalApprove.active"
                       :idPengadaan="modalFinalApprove.idPengadaan"
                       @success="onSigned"/>
  </div>
</template>

<script>
import PengadaanRepository from '@/repositories/procurement/pengadaan-repository'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'PengadaanShow',
  props: {
    showButtonSigningAction: { type: Boolean, default: false }
  },
  mixins: [printoutMixin],
  components: {
    PurchaseRequestSigning: () => import('@/views/pages/approvals/purchase-request-approval/PurchaseRequestApprovalSigning'),
    PurchaseRequestFinalApprove: () => import('@/views/pages/approvals/purchase-request-approval/PurchaseRequestApprovalFinalApprove'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      data: {
        pengadaan: {},
        pengadaanD: []
      },
      modalSigning: {
        active: false,
        idPengadaan: this.$route.params.idPengadaan,
        status: null
      },
      modalFinalApprove: {
        active: false,
        idPengadaan: this.$route.params.idPengadaan
      }
    }
  },
  computed: {
    isFinalSigning () {
      return this.data.pengadaan.current_step === this.data.pengadaan.final_step
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoading = true

      const idPengadaan = this.$route.params.idPengadaan
      PengadaanRepository.show(idPengadaan)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildInitData (initData) {
      const pengadaanDItems = initData.pengadaanD
      let pengadaanDItemsUnique = _.uniqBy(pengadaanDItems, item => item.id_item_pengadaan)
      pengadaanDItemsUnique = _.map(pengadaanDItemsUnique, item => {
        return {
          ...item,
          vendors: _.cloneDeep(_.filter(pengadaanDItems, d => (d.is_has_vendors && d.id_item_pengadaan === item.id_item_pengadaan))),
          showVendors: false
        }
      })
      this.data = initData
      this.data.pengadaanD = pengadaanDItemsUnique
    },

    onClickSigningAction (status) {
      if (status === 'DITERIMA' && this.isFinalSigning) {
        this.modalFinalApprove.active = true
      } else {
        this.modalSigning.status = status
        this.modalSigning.active = true
      }
    },

    print () {
      const id = this.$route.params.idPengadaan
      this.showPrintOutFile('PURCHASE_REQUEST', { ids: [id] })
    }
  }
}
</script>
